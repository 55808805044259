.swal2-popup{
    font-size: 1.3rem!important;
    line-height: 1!important;
}
.swal2-title {
    font-family: 'PSLDisplayProBold';
    font-weight: normal !important;
}
.swal2-styled{
    font-family: 'PSLDisplayProBold';
    font-weight: normal !important;
    /* font-size: 1.5em !important; */
    padding: .125em 1.5em !important;
}

button:focus, a:focus{
    outline: none;
}
