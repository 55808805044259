@mixin font-face($font-family) {
	@font-face {
		font-family: '#{$font-family}';
		src: url('../fonts/#{$font-family}.eot');
		src: url('../fonts/#{$font-family}.eot?#iefix') format('embedded-opentype'),
		     url('../fonts/#{$font-family}.woff') format('woff'),
		     url('../fonts/#{$font-family}.ttf') format('truetype'),
		     url('../fonts/#{$font-family}.svg') format('svg');
		font-weight: normal;
		font-style: normal;
	}
}

@include font-face('PSLDisplayPro');
@include font-face('PSLDisplayProBold');

body{
	font-family: 'PSLDisplayPro, sans-serif';
	margin: 0;
}

input:read-only {
	background-color: rgb(235, 235, 235);
  }

  .my-custom-snackbar {
	margin: 0 !important;
	position: absolute;
	//right: 25px;
	top: 10em;
  }